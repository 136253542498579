/*eslint-disable */
export const token_key = "@FrotaAdvisor_token"

export const isAuthenticated = () => {
    return getToken() !== null
}

export const getToken = () => {
    return sessionStorage.getItem(token_key)
}
