/*eslint-disable */
import React, { Suspense } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import { isAuthenticated } from './services/auth'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const PerfilLayout = React.lazy(() => import('./layout/PerfilLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))


// const PasswordRecovery = React.lazy(() => import('./views/pages/register/PasswordRecovery'))

const PrivateRoute = ({ children }) => {
  const auth = isAuthenticated()
  return auth ? children : <Navigate to="/login" />
}

const App = () => {

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<PrivateRoute><DefaultLayout /></PrivateRoute>} />
          <Route path="/perfil" element={<PrivateRoute><PerfilLayout /></PrivateRoute>} />
          {/* <Route path="/password-recovery" element={<PasswordRecovery />} /> */}
        </Routes>
      </Suspense>
    </HashRouter>
  )

}

export default App

